/*
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
* Functions for duedate calculation
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
*/

const selectors = {
  dueDateField: '#payment_donation_custom_field_2465'
}

const date = {
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
  year: new Date().getFullYear()
}

export function setDueDate(donationForm, interval) {
  const dueDateField = donationForm.querySelector(selectors.dueDateField);
  const dueDateCalculated = calcDueDate(date.day, date.month, date.year, interval);
  dueDateField.value = dueDateCalculated;
}


function calcDueDate(day, month, year, interval) {
  let dueDate;

  if (isSepaDirectDebit() && isOneOffDonation(interval) && isEndOfYear(day, month)) {
    dueDate = endOfYearCalculation(day, month, year);
  } else {
    dueDate = defaultCalculation(day, month, year);
  }

  return dueDate;
}

function isEndOfYear(day, month) {
  // this is a special calculation, x and y are defined by customer each year
  // end of year is true if : december and date between x and y
  const x = 14,
    y = 31;
  return (day >= x && day <= y && month === 12) ? 1 : 0;
}

function endOfYearCalculation(day, month, year) {
  let z = 28;

  if (day >= 14 && day <= 27) {
    z = 30;
    month = 12;
  }

  if (day >= 28) {
    z = 11;
    month = 1;
    year++;
  }

  return getFormattedDate(z, month, year);
}

function isSepaDirectDebit() {
  const sepaPayment = document.querySelector('#sepa_direct_debit');

  if (sepaPayment) {
    return sepaPayment.checked ? 1 : 0;
  } else {
    return false;
  }
}

function isOneOffDonation(interval) {
  if (interval) {
    return interval === '0' ? 1 : 0;
  } else {
    return true;
  }
}

function defaultCalculation(day, month, year) {

  // Default calculation logic:
  //  1, 2, 3 => 11.x.
  //  4, 5… 13 => 21.x.
  //  14, 15… 23 => 1.x+1.
  //  24… => 11.x+1.

  let dueDate;

  if (day >= 1 && day <= 3) {
    dueDate = getFormattedDate(11, month, year);
  }
  if (day > 3 && day <= 13) {
    dueDate = getFormattedDate(21, month, year);
  }
  if (day >= 14 && day <= 23) {
    if (month === 12) {
      dueDate = getFormattedDate(1, 1, (year + 1));
    } else {
      dueDate = getFormattedDate(1, (month + 1), year);
    }
  }

  if (day >= 24) {
    if (month === 12) {
      dueDate = getFormattedDate(11, 1, (year + 1));
    } else {
      dueDate = getFormattedDate(11, (month + 1), year);
    }
  }

  return dueDate;
}

function getFormattedDate(day, month, year) {
  let dateDivider = '.';

  let formattedDay = day < 10 ? "0" + day : day.toString();
  let formattedMonth = month < 10 ? "0" + month : month.toString();

  return formattedDay + dateDivider + formattedMonth + dateDivider + year.toString();
}
